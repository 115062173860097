<template>
  <div>

    <el-tooltip
      :content="row.status
        ? $companyConfiguration.cardStatuses.getStatusShowName(row.status)
        : ''"
      placement="top"
      :open-delay="100"
    >
      <i
        class="fas fa-info-circle"
        :class="{
        danger: [10,51,60].includes(row.status),
        success: [50,59,200].includes(row.status),
      }"
      ></i>
    </el-tooltip>


  </div>
</template>

<script>

export default {
  name: "current-stage-column-template",
  components: {},

  props: {
    row: {type: Object, required: true}
  },

  data() {
    return {}
  },
  mounted() {
  },
  watch: {},
  computed: {},
  methods: {},

}
</script>

<style scoped lang="scss">
@import "./src/assets/css/project-variables";

.danger{
  color: $--color-primary;
}
.success{
  color: $--color-success;
}
</style>